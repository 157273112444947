<template>
  <view-wrapper title="我的库存">
    <div class="myform p-24px pb-[0px]" style="min-width: 1284px; background: #fff">
      <form-query :model="form.data" @search="form.search">
        <a-row>
          <a-col :span="5">
            <a-form-item label="配件名称">
              <a-input @pressEnter="form.search" v-model:value="form.data.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="配件编码">
              <a-input @pressEnter="form.search" v-model:value="form.data.serialNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="车品牌">
              <a-input @pressEnter="form.search" v-model:value="form.data.brand" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="品质类型">
              <e-select
                @change="form.search"
                placeholder="请选择"
                :ajax="{ get: { action: 'GET /common/lookup/IQYQLYTYPE' } }"
                :allowClear="false"
                v-model="form.data.type"
                :props="{ label: 'message', value: 'code' }"
                value-key="id"
              ></e-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" class="flex justify-end">
            <a-button @click="form.init">重置</a-button>
            <!-- <a-button @click="form.search" style="margin-left: 10px" type="primary">查询</a-button> -->
          </a-col>
        </a-row>
      </form-query>
    </div>

    <div class="my-content">
      <div class="flex justify-between items-center" style="margin-bottom: 24px">
        <div class="font-bold">共享库存列表</div>
        <span>
          <a-button type="primary" @click="drawerNew.onOpen" class="flex items-center">
            <template #icon>
              <PlusOutlined />
            </template>
            新建
          </a-button>
        </span>
      </div>
      <a-table
        :columns="table.columns"
        :data-source="table.data"
        :loading="table.loading"
        pagination
        :paging="table.paging"
        :method="table.get"
        row-key="id"
      >
        <template #imgUrl="{ record }">
          <span v-if="!record.imgUrl">无</span>
          <a class="text-primary" v-else @click="imgPreview.click(record.imgUrl)">查看</a>
        </template>
        <template #operation="{ record }">
          <span class="operation-model">
            <a class="text-[]" @click="table.disable.click(record)">删除</a>
          </span>
        </template>
      </a-table>
    </div>
  </view-wrapper>
  <a-drawer title="添加共享" :width="480" :visible="drawerNew.visible" @close="drawerNew.visible = false">
    <a-form :model="drawerNew.form.data" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="配件名称">
            <a-input :maxlength="20" v-model:value="drawerNew.form.data.name" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车品牌">
            <e-select
              placeholder="请选择"
              :ajax="{ get: { action: 'GET /common/brand' } }"
              :allowClear="false"
              show-search
              :filter-option="filterOption"
              v-model="drawerNew.form.data.brand"
              :props="{ label: 'name', value: 'name' }"
              value-key="id"
            ></e-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="配件编码">
            <a-input v-model:value="drawerNew.form.data.serialNo" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="品质类型">
            <e-select
              placeholder="请选择"
              :ajax="{ get: { action: 'GET /common/lookup/IQYQLYTYPE' } }"
              :allowClear="false"
              v-model="drawerNew.form.data.type"
              :props="{ label: 'message', value: '' }"
              value-key="code"
            ></e-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="销售价格">
            ￥
            <a-input-number
              style="width: 90%"
              :min="0"
              v-model:value.number="drawerNew.form.data.price"
              :precision="2"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="库存">
            <e-input-number style="width: 90%" v-model="drawerNew.form.data.count"></e-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-form-item label="图片上传" style="flex-wrap: nowrap">
          <e-upload v-model="drawerNew.form.data.imgUrls" dir="inventory" :max="1"></e-upload>
        </a-form-item>
      </a-row>
    </a-form>
    <div class="drawer-footer">
      <a-button style="margin-right: 8px" @click="drawerNew.visible = false">取消</a-button>
      <a-button type="primary" :loading="addSharedLoading" @click="drawerNew.confirm.click">发布库存</a-button>
    </div>
  </a-drawer>
  <a-modal v-model:visible="imgPreview.visible" :footer="null" @cancel="imgPreview.visible = false">
    <div class="mt-20px">
      <img alt="image" style="width: 100%" :src="imgPreview.imgUrl" />
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { useAjax, useTable, useStore, formatMoney } from '@vue-mfe/utils'
import { reactive } from 'vue'
import {
  Row as ARow,
  Col as ACol,
  Drawer as ADrawer,
  Modal as AModal,
  InputNumber as AInputNumber,
  message
} from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import type { ColumnProps } from 'ant-design-vue/es/table/interface'

const columns = [
  { title: '序号', customRender: ({ index }) => index + 1 },
  { title: '配件名称', dataIndex: 'name' },
  { title: '车品牌', dataIndex: 'brand' },
  { title: '配件编码', dataIndex: 'serialNo' },
  { title: '品质类型', dataIndex: 'type.message' },
  { title: '库存数量', dataIndex: 'count' },
  { title: '单价', customRender: ({ record }) => formatMoney(record.price, '￥') },
  { title: '报价人', dataIndex: 'preparedBy.name' },
  { title: '配件图片', slots: { customRender: 'imgUrl' } },
  { title: '操作', slots: { customRender: 'operation' } }
] as ColumnProps[]

const store = useStore()
const supplierId = store.state.user?.supplier?.id
const { run: addShared, loading: addSharedLoading } = useAjax(
  {
    action: 'POST /supplier/sharedinventory',
    params: () => drawerNew.form.data,
    lazy: true
  }
)
const form = reactive(
  {
    data: {
      name: '',
      serialNo: '',
      brand: '',
      type: ''
    },
    init () {
      form.data.name = ''
      form.data.serialNo = ''
      form.data.brand = ''
      form.data.type = ''
    },
    search () {
      table.get()
    }
  }
)
const table = useTable(
  {
    ajax: {
      get: {
        action: 'GET /supplier/sharedinventory',
        params: () => ({ ...form.data, supplierId })
      }
    },
    columns,
    disable: {
      click (record: any) {
        useAjax(
          {
            action: 'PUT /supplier/sharedinventory/:sharedInventoryId/disable',
            params: () => [record.id],
            success () {
              table.get()
            }
          }
        )
      }
    }
  }
)
const filterOption = (input: string, option: any) => {
  return option.value.toLowerCase().includes(input.toLowerCase())
}
const drawerNew = reactive(
  {
    visible: false,
    form: {
      data: {
        name: '',
        serialNo: '',
        goods: {
          name: '',
          serialNo: ''
        },
        brand: '',
        type: {
          code: ''
        },
        imgUrls: [],
        imgUrl: '',
        count: 0,
        price: 0
      },
      init () {
        drawerNew.form.data.goods = {
          name: '',
          serialNo: ''
        }
        drawerNew.form.data.type = {
          code: ''
        }
        drawerNew.form.data.name = ''
        drawerNew.form.data.imgUrls = []
        drawerNew.form.data.imgUrl = ''
        drawerNew.form.data.serialNo = ''
        drawerNew.form.data.brand = ''
        drawerNew.form.data.count = 0
        drawerNew.form.data.price = 0
      }
    },
    onOpen () {
      drawerNew.form.init()
      drawerNew.visible = true
    },
    confirm: {
      click () {
        if (!drawerNew.form.data.name) {
          return message.warning('请输入配件名称')
        }
        if (!drawerNew.form.data.brand) {
          return message.warning('请选择车品牌')
        }
        if (!drawerNew.form.data.serialNo) {
          return message.warning('请输入配件编码')
        }
        if (!drawerNew.form.data.type.code) {
          return message.warning('请选择品质类型')
        }
        if (!drawerNew.form.data.count) {
          return message.warning('库存数不能为0')
        }
        if (!drawerNew.form.data.price) {
          return message.warning('售价不能为0')
        }
        drawerNew.form.data.imgUrl = drawerNew.form.data.imgUrls[0]
        addShared().then(
          () => {
            message.success('添加成功')
            drawerNew.visible = false
            table.get()
          }
        )
      }
    }
  }
)
const imgPreview = reactive(
  {
    visible: false,
    imgUrl: '',
    click (url: string) {
      imgPreview.imgUrl = url
      imgPreview.visible = true
    }
  }
)
</script>

<style scoped>
.my-content {
  margin-top: 24px;
  padding: 24px;
  background: #fff;
}
.table-block {
  display: flex;
  flex-wrap: wrap;
  min-width: 1284px;
}
.item-card {
  width: calc((100% - 48px) / 3);
  min-width: 378px;
  box-sizing: border-box;
  height: 128px;
  padding: 16px 24px;
  background: #fff;
  margin-top: 24px;
  margin-left: 24px;
}
.item-card:nth-child(3n + 1) {
  margin-left: 0;
}
</style>
